import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { NotificationsService } from '@app-shared/services/notifications.service';
import { TranslocoService } from '@ngneat/transloco';
import { catchError, combineLatest, take, throwError } from 'rxjs';

/** A header to skip the server error interceptor */
export const SkipErrorInterceptor = 'X-Skip-Error-Interceptor';
/** A header to skip the server error interceptor */
export const SkipErrorInterceptorHeader = new HttpHeaders({
  'X-Skip-Error-Interceptor': '',
});

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const notificationsService = inject(NotificationsService);
  const translocoService = inject(TranslocoService);

  if (req.headers && req.headers.has(SkipErrorInterceptor)) {
    const headers = req.headers.delete(SkipErrorInterceptor);

    return next(req.clone({ headers }));
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      // check added for '/invites/assessment/link/assessment/{assessmentFormId}' endpoint to prevent interceptor for throwing error message for 403 forbidden
      if (error.url && error.url.includes('/invites/assessment/link/assessment') && error.status === 403) {
        return throwError(() => error);
      }

      if (error.status === 500) {
        translocoService
          .selectTranslate('errors.defaultErrorMessage')
          .pipe(take(1))
          .subscribe(errorMessage => {
            notificationsService.errorSnackbar(errorMessage);
          });
      } else {
        combineLatest({
          apiErrorMsg: translocoService.selectTranslate(error.error.message),
          defaultErrorMsg: translocoService.selectTranslate('errors.defaultErrorMessage'),
        })
          .pipe(take(1))
          .subscribe({
            next: ({ apiErrorMsg, defaultErrorMsg }) => {
              notificationsService.errorSnackbar(apiErrorMsg || defaultErrorMsg);
            },
          });
      }

      return throwError(() => error);
    }),
  );
};
